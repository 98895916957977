import React, { useEffect, useState } from "react";
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilled.js";
import InfoIcon from "../../assets/Icons/InfoIcon";
import { GetObjectKey } from "../../helpers/getObjectKeyFromValue";
import ExamType from "../../enums/ExamType";
import { useMediaQuery } from "react-responsive";
import { Components } from "../../assets/Dimensions";
import "./style.css"

const ImageComponent = ({openReportModal, questionData }) => {

    const [isOpen, setIdOpen] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  console.log("questionData", questionData);

  useEffect(() => {
    console.log("window.screen.width", window.screen.width);
    console.log(
      "questionData.QuestionPartImageUrl",
      questionData.QuestionPartImageUrl
    );
  }, [window.screen.width]);

  const openModal = () => {
    setIdOpen(!isOpen)
  }
  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      paddingTop: 20,
      paddingRight: 15,
      marginBottom: 50,
      marginTop: "4rem",
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  return (
    <div style={{}}>
      {isOpen&&questionData.AnswerVideoPath&&(<div className="modal-overlay" onClick={openModal}>
        <div className="modal-content">
          <button className="close-button" onClick={openModal}>
            Close
          </button>
          <div className="video-container">
            {/* Your video component or embed code goes here */}
            <iframe
              width="560"
              height="315"
              src={questionData.AnswerVideoPath}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>)}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          //   maxWidth: "min-content",
          //overflow: "auto",
          //height: window.screen.height / 2
        }}
      > */}
       
        <div className="ImageContainer">
        <div className="questionAnswer">{questionData.CorrectAnswer}</div>
          <img
            className="mainImage"
            style={{
              width: window.screen.width * 0.8,
              height: "auto",
              maxWidth: window.screen.width > 1000 && window.screen.width * 0.3,
              maxHeight:
                window.screen.width > 1000 && window.screen.height / 1.5,
              // width: "50%",
              // height: "auto",
              // maxWidth: "60%",

              // width: "100%",
              // height: "auto"
            }}
            src={questionData.QuestionUrl}
          />
          
          <div
        className="questionInfoVideoContainer"
          style={{
            // position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-araound",
            gap: "1rem",
          }}
        >
         {questionData.AnswerVideoUrl&& <div onClick={openModal}>
            <PlayCircleIcon />
          </div>}

          <div onClick={openReportModal}>
            <InfoIcon  />
          </div>
        </div>
          {questionData.QuestionPartUrl &&
             (
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth:
                      window.screen.width > 1000
                        ? window.screen.width * 0.3
                        : window.screen.width,
                    maxHeight: window.screen.height / 2,
                    height: "auto",
                  }}
                  src={questionData.QuestionPartUrl}
                />
              </div>
            )}
        </div>
      {/* </div> */}
    </div>
  );
};

export default ImageComponent;
